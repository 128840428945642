import DateFnsUtils from '@date-io/moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import {
  Controller,
  FormProvider,
  RegisterOptions,
  Resolver,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useAsyncResult } from 'react-use-async-result';
import { useDebounce } from 'usehooks-ts';
import * as yup from 'yup';
import { foodApi, mealApi } from '../api';
import {
  CreateMealItemRequest,
  CreateMealRequest,
  CreateMealRequestMealNameEnum,
  FoodResponse,
  MealItemCustomAddonRequest,
  MealResponse,
  PatientNutritionDetailsTableRow,
} from '../api/generated';
import { useCurrentPatientData, useStore } from '../context';
import { Trans, useTranslation } from '../i18n';
import {
  ActivityFeedItem,
  MACRO_NUTRIENT_QUERY_KEY,
  MacroNutrientSummaryCard,
  useMealCache,
} from '../pages/patient-logV2';
import MealThumbnailImage from '../theme/icons/meal-thumb.png';
import { FormatAMPMStr, formatDate, formatDateLongMonth, formatTime, getNearestMealName } from '../utils/formatDate';
import { maybe, titleCase } from '../utils/formatters';
import { CGMMealGlucoseChart } from './cgm/CGMGlucoseChart';
import { OriginalImage } from './image';
import { itemAddonsToStr, MealItemMacros, MealItemPercentEaten } from './mealCard';
import './showMealV2.scss';
import { select } from 'd3';
import { create } from 'zustand';
import { MuiCustomSelect, MuiCustomTextField } from './muiCustomInput';
import { PatientSurvey } from './patientSurvey';

function textJoiner(idx: number, length: number) {
  if (idx === length - 1) {
    return '';
  }
  return ', ';
}

export const AutoScrollHere = () => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, []);
  return <div ref={scrollRef} />;
};
export const useRecentlyModifiedMeal = create(() => ({
  recentlyModifiedMeal: null as MealResponse | null,
}));

const ItemOrAddonEditRow = (props: {
  itemOrAddon: CreateMealItemRequest | MealItemCustomAddonRequest,
  type: string,
  onChange: (fields: Partial<CreateMealItemRequest | MealItemCustomAddonRequest>) => void,
  onRemove: () => void,
  itemIndex?: number,
  addonIndex?: number,
  rowStyle?: React.CSSProperties,
}) => {
  const { itemOrAddon, type, itemIndex, addonIndex } = props;
  const { patient } = useStore();
  const { t, tAttr } = useTranslation();
  const { register, formState: { errors, isSubmitted } } = useFormContext();

  const foodQuery = useQuery(['food', itemOrAddon.food_name], async () => {
    const res = await foodApi.appApiFoodFoodSearchGetFoodQuery({
      food_name: itemOrAddon.food_name,
    });
    return res.data;
  });

  const [foodSearchTerm, setFoodSearchTerm] = useState(null as string | null);
  const debounceFoodSearchTerm = useDebounce(foodSearchTerm, 100);
  const foodSearchQuery = useQuery(['foodSearch', debounceFoodSearchTerm], async () => {
    if (!foodSearchTerm) {
      return [];
    }
    const res = await foodApi.appApiFoodFoodSearchSearchByText({
      search_text: foodSearchTerm,
      context_user_id: '' + patient.patient_id,
    });
    return res.data.results;
  }, {
    enabled: foodSearchTerm == debounceFoodSearchTerm,
  });

  const inputRef = React.useRef<HTMLInputElement>(null);

  const controlRegister = type === 'meal_items'
    ? `${type}.${itemIndex}`
    // eslint-disable-next-line i18next/no-literal-string
    : `meal_items.${itemIndex}.custom_addons.${addonIndex}`;

  return (
    <Row style={{ display: 'flex', gap: 10, paddingLeft: 15, marginTop: 0, alignItems: 'flex-end' }}>
      <div style={{ flex: 1, marginTop: 10 }}>
        <Autocomplete
          loading={foodSearchQuery.isLoading}
          options={(foodSearchQuery.data || []).map(f => {
            const food_name = tAttr(f as FoodResponse & { name_translations: object | null }, 'name');
            return food_name;
          })}
          onChange={(event, value) => {
            props.onChange({ food_name: value });
          }}
          onBlur={(event) => {
            // foodNameRegister.onBlur(event);
          }}
          value={itemOrAddon.food_name}
          onInputChange={(event, value) => {
            setFoodSearchTerm(value);
          }}
          renderInput={(params) => (
            <MuiCustomTextField
              {...params}
              ref={inputRef}
              {...register(`${controlRegister}.food_name`)}
              label={t('Food name')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {foodSearchQuery.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        {errors.meal_items?.[itemIndex]?.food_name && isSubmitted && type === 'meal_items'
          && <p className="text-danger">{errors?.meal_items[itemIndex]?.food_name?.message}</p>}
        {errors.meal_items?.[itemIndex]?.custom_addons && isSubmitted && type === 'custom_addons'
          && (
            <p className="text-danger">
              {errors?.meal_items[itemIndex]?.custom_addons[addonIndex]?.food_name?.message}
            </p>
          )}
      </div>
      <div style={{ flex: '0 0 75px', width: 75, marginTop: -20 }}>
        <MuiCustomTextField
          variant="outlined"
          label={t('Count')}
          type="number"
          {...register(`${controlRegister}.servings`, { valueAsNumber: true })}
          value={itemOrAddon.servings}
          onChange={(event) => {
            props.onChange({
              servings: +event.target.value < 0 ? 0 : +event.target.value,
            });
          }}
        />
        {errors.meal_items?.[itemIndex]?.servings && isSubmitted && type === 'meal_items'
          && <p className="text-danger">{errors?.meal_items[itemIndex]?.servings?.message}</p>}
        {errors.meal_items?.[itemIndex]?.custom_addons && isSubmitted && type === 'custom_addons'
          && (
            <p className="text-danger">
              {errors?.meal_items[itemIndex]?.custom_addons[addonIndex]?.servings?.message}
            </p>
          )}
      </div>
      <div style={{ flex: 0.5, marginTop: -20 }}>
        <FormControl variant="outlined" className="meal-unit-input">
          <InputLabel id="serving_unit_label">
            <Trans>Unit</Trans>
          </InputLabel>
          <MuiCustomSelect
            id="serving_unit_label"
            value={itemOrAddon.serving_unit_label}
            variant="outlined"
            style={{ height: '56px' }}
            label={t('Unit')}
            {...register(`${controlRegister}.serving_unit_label`)}
            disabled={!foodQuery.data?.serving_units}
            onChange={(event) => {
              const servingUnitAmount = foodQuery.data?.serving_units
                ?.find(su => su.label === event.target.value)
                ?.amount;
              props.onChange({
                serving_unit_label: '' + event.target.value,
                serving_unit_amount: servingUnitAmount || 0,
              });
            }}
          >
            {foodQuery.data?.serving_units?.map((su, index) => (
              <MenuItem key={index} value={su.label}>
                {su.label}
              </MenuItem>
            ))}
          </MuiCustomSelect>
        </FormControl>
        {errors.meal_items?.[itemIndex]?.serving_unit_label && isSubmitted && type === 'meal_items'
          && <p className="text-danger">{errors?.meal_items[itemIndex]?.serving_unit_label?.message}</p>}
        {errors.meal_items?.[itemIndex]?.custom_addons && isSubmitted && type === 'custom_addons'
          && (
            <p className="text-danger">
              {errors?.meal_items[itemIndex]?.custom_addons[addonIndex]?.serving_unit_label?.message}
            </p>
          )}
      </div>
      <div style={{ width: 20, display: 'flex', alignSelf: 'center', marginTop: '-5px', marginLeft: '10px' }}>
        <span role="button" style={{ cursor: 'pointer' }} onClick={() => props.onRemove()}>
          <i className="fas fa-trash text-danger" />
        </span>
      </div>
    </Row>
  );
};

const EditMealItemRow = (props: {
  mealItem: CreateMealItemRequest & { food_photo_url?: string },
  register: (name: keyof CreateMealItemRequest, options?: RegisterOptions) => any,
  mealIndex: number,
  onChange: (fields: Partial<CreateMealItemRequest>) => void,
  onRemove: () => void,
}) => {
  const { mealItem, mealIndex } = props;
  const addons = mealItem.custom_addons || [];
  const { t } = useTranslation();
  return (
    <Row className="dx-0 bx-1" style={{ marginLeft: 0, marginRight: 0 }}>
      <Col xs="auto" className="py-3 d-flex" style={{ gap: '20px', paddingLeft: 0, paddingRight: 0 }}>
        <img
          src={mealItem.food_photo_url || MealThumbnailImage}
          width={80}
          height={80}
        />
      </Col>
      <Col className="py-3" style={{ flex: 1 }}>
        <ItemOrAddonEditRow
          itemOrAddon={mealItem}
          type="meal_items"
          itemIndex={mealIndex}
          rowStyle={{ marginBottom: 20 }}
          onChange={(fields) => {
            props.onChange(fields);
          }}
          onRemove={() => props.onRemove()}
        />
        <Row>
          <Col>
            <h5 className="mt-4">
              <span
                role="button"
                className="py-1"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  props.onChange({
                    'custom_addons': [
                      ...addons,
                      {
                        food_name: '',
                        servings: 1,
                        serving_unit_label: '',
                        serving_unit_amount: 0,
                      },
                    ],
                  })}
              >
                <Trans>Addons</Trans>{'  '}<i className="fas fa-plus mr-1" />
              </span>
            </h5>
          </Col>
        </Row>
        <div className="mt-3" style={{ display: 'flex', gap: 15, flexDirection: 'column' }}>
          {addons.map((addon, index) => (
            <ItemOrAddonEditRow
              key={index}
              itemOrAddon={addon}
              type="custom_addons"
              itemIndex={mealIndex}
              addonIndex={index}
              rowStyle={{ marginBottom: 20 }}
              onChange={(fields) => {
                props.onChange({
                  custom_addons: addons.map(a => {
                    if (a === addon) {
                      return {
                        ...a,
                        ...fields,
                      };
                    }
                    return a;
                  }),
                });
              }}
              onRemove={() => {
                props.onChange({
                  custom_addons: addons.filter(a => a !== addon),
                });
              }}
            />
          ))}
        </div>
        <Row>
          <Col>
            <h5 className="my-4">
              <Trans>Note</Trans>
            </h5>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginTop: -20 }}>
            <MuiCustomTextField
              placeholder={t('Notes...')}
              variant="outlined"
              multiline
              onChange={(event) => {
                props.onChange({ note: event.target.value as string });
              }}
              value={mealItem.note}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const ShowMealV2 = (props: {
  selectedMeal: MealResponse | null,
  initialMode: 'view' | 'edit' | 'add',
  activityData: ActivityFeedItem[],
  onHide: () => void,
}) => {
  const saveMealReq = useAsyncResult<MealResponse>();
  const deleteMealReq = useAsyncResult();
  const { patient, clinician } = useStore();
  const { i18n, t, tAttr } = useTranslation();
  const [displayMode, setDisplayMode] = useState(props.initialMode);
  const patientFlags = useCurrentPatientData();
  const queryClient = useQueryClient();

  const selectedMeal = saveMealReq.isDone ? saveMealReq.result : props.selectedMeal;

  useEffect(() => {
    setDisplayMode(props.initialMode);
  }, [props.initialMode, selectedMeal]);

  const [mealPhotoShowLarge, setMealPhotoShowLarge] = useState(false);

  const editing = displayMode === 'edit' || displayMode === 'add';

  const getEmptyMealItem = (): CreateMealItemRequest => ({
    food_name: '',
    servings: 1,
    serving_unit_label: '',
    serving_unit_amount: 0,
    addons: [],
    extra_addons: [],
    note: '',
  });

  const getMealForEdit = () => {
    if (selectedMeal) {
      return selectedMeal;
    }

    const now = DateTime.local();

    return {
      meal_name: getNearestMealName(patient as any, now.toJSDate()),
      meal_date: formatDate(now),
      meal_time: formatTime(now),
      meal_items: [getEmptyMealItem()],
    };
  };

  const formValidationSchema = yup.object({
    meal_date: yup.string().required(),
    meal_time: yup.string().required(),
    meal_name: yup.string().required(),
    meal_items: yup.array().of(
      yup.object().shape({
        food_name: yup.string().required(t('Food name is required')),
        servings: yup.number().required(t('Count is required')),
        serving_unit_label: yup.string().required(t('Unit is required')),
        serving_unit_amount: yup.number().required(),
        custom_addons: yup.array().of(
          yup.object().shape({
            food_name: yup.string().required(t('Food name is required')),
            servings: yup.number().required(t('Count is required')),
            serving_unit_label: yup.string().required(t('Unit is required')),
            serving_unit_amount: yup.number().required(),
          }),
        ),
      }),
    ),
  });

  const form = useForm<CreateMealRequest>({
    reValidateMode: 'onChange',
    defaultValues: getMealForEdit(),
    resolver: yupResolver(formValidationSchema) as Resolver<any>,
  });
  const mealItemsField = useFieldArray({
    control: form.control,
    name: 'meal_items',
    keyName: '_rhf_id',
  });

  const handleHide = () => {
    switch (displayMode) {
      case 'edit':
        // eslint-disable-next-line i18next/no-literal-string
        setDisplayMode('view');
        break;
      case 'add':
        if (confirm(t('Are you sure you want to discard this meal?'))) {
          props.onHide();
        }
        break;
      case 'view':
        props.onHide();
        break;
    }
  };

  const handleSaveMeal = async () => {
    const res = _handleSaveMeal();
    saveMealReq.bind(res);
    const [meal, err] = await maybe(res);
    if (err) {
      alert(t('Unexpected error saving meal: ') + err);
      return;
    }
    if (!meal) {
      return;
    }

    useMealCache.setState(state => ({
      ...state,
      [meal.id]: meal,
    }));

    if (displayMode == 'add') {
      props.onHide();
    }

    // eslint-disable-next-line i18next/no-literal-string
    setDisplayMode('view');
  };

  const handleDeleteMeal = async () => {
    const conf = confirm(t('Are you sure you want to permanently delete this meal?'));

    if (!conf) {
      alert(t('Delete cancelled'));
      return true;
    }

    const res = _handleDeleteMeal();
    deleteMealReq.bind(res);
    const [response, err] = await maybe(res);

    if (err) {
      alert(`${t('Error deleting meal')}: ${err}`);
      return;
    }

    useMealCache.setState(state => ({
      ...state,
      [selectedMeal.id]: {
        ...state[selectedMeal.id],
        is_deleted: true,
      },
    }));
    props.onHide();
    // eslint-disable-next-line i18next/no-literal-string
    setDisplayMode('view');
  };

  const _handleDeleteMeal = async () => {
    const res = await mealApi.appApiMealDeleteMeal({
      patient_id: patient.patient_id,
      meal_id: selectedMeal.id,
    });

    queryClient.resetQueries({ queryKey: [MACRO_NUTRIENT_QUERY_KEY] });
    return res;
  };

  const MacroSummaryCard = React.useMemo(() => {
    if (!selectedMeal || editing) {
      return null;
    }
    const date = DateTime.fromISO(selectedMeal.meal_date);
    return (
      <div style={{ width: 460 }}>
        <MacroNutrientSummaryCard summaryDate={date} mealId={selectedMeal.id} meal={selectedMeal} />
      </div>
    );
  }, [selectedMeal, editing]);

  const _handleSaveMeal = async () => {
    const formValues = form.control._formValues as CreateMealRequest;
    if (displayMode == 'add') {
      const res = await mealApi.appApiMealPostMeals({
        patient_id: patient.patient_id,
        create_queue: true,
        CreateMealRequest: {
          meal_date: formValues.meal_date,
          meal_name: formValues.meal_name.toLowerCase() as CreateMealRequestMealNameEnum,
          meal_time: formValues.meal_time,
          meal_items: formValues.meal_items?.map(item => ({
            ...item,
            meal_photo_id: null,
            meal_photo_url: null,
            extra_addons: [],
          })) || [],
        },
      });
      useRecentlyModifiedMeal.setState({ recentlyModifiedMeal: res.data });
      return res.data;
    }

    const mealId = (formValues as any).id as number;
    if (!mealId) {
      throw new Error('Attempt to edit meail without id: ' + JSON.stringify(formValues));
    }

    // 1. Add any new items
    const addedItems = formValues.meal_items?.filter(item => !(item as any).id) || [];
    addedItems.length > 0 && await mealApi.appApiMealPostMealItems({
      patient_id: patient.patient_id,
      meal_id: mealId,
      CreateMealItemRequest: addedItems,
    });

    // 2. Update any changed items
    const existingItems = selectedMeal?.meal_items || [];
    const updatedItems = formValues.meal_items?.filter(item => {
      return existingItems.find(i => (i.id == (item as any).id && i !== item));
    }) || [];
    for (const item of updatedItems) {
      await mealApi.appApiMealPutMealItem({
        patient_id: patient.patient_id,
        meal_id: mealId,
        meal_item_id: (item as any).id,
        UpdateMealItemRequest: item,
      });
    }

    // 3. Delete any removed items
    const removedItems = existingItems.filter(item => {
      return !formValues.meal_items?.find(i => (i as any).id == item.id);
    });
    for (const item of removedItems) {
      await mealApi.appApiMealDeleteMealItem({
        patient_id: patient.patient_id,
        meal_id: mealId,
        meal_item_id: item.id,
      });
    }

    // 4. Update the meal
    const res = await mealApi.appApiMealPutMeal({
      patient_id: patient.patient_id,
      meal_id: mealId,
      UpdateMealRequest: {
        meal_date: formValues.meal_date,
        meal_name: formValues.meal_name.toLowerCase() as CreateMealRequestMealNameEnum,
        meal_time: formValues.meal_time,
      },
    });

    // delete meal if it has no items
    if (formValues.meal_items?.length === 0) {
      const res = await handleDeleteMeal();
      if (!res) {
        return;
      }
    }

    queryClient.resetQueries({ queryKey: [MACRO_NUTRIENT_QUERY_KEY] });
    return res.data;
  };

  return (
    <FormProvider {...form}>
      <Modal show={true} onHide={props.onHide} className="mealview-modal">
        <Modal.Body>
          <Row className="popup-header">
            {selectedMeal?.meal_photo_id && !mealPhotoShowLarge && (
              <Col xs="auto">
                <OriginalImage
                  mealPhotoId={selectedMeal?.meal_photo_id}
                  style={{
                    width: 250,
                    height: 250,
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                  onClick={() => setMealPhotoShowLarge(true)}
                />
              </Col>
            )}

            {!editing
              ? (
                <>
                  <Col xs="auto">
                    <div className="d-flex">
                      <h5 className="text-muted">{formatDateLongMonth(i18n.language, selectedMeal?.meal_date)}</h5>
                      {/* eslint-disable-next-line i18next/no-literal-string */}
                      <div className="mx-2 text-muted">&bull;</div>
                      <div className="text-muted">{FormatAMPMStr(selectedMeal?.meal_time)}</div>
                    </div>
                    <h4>{titleCase(t(selectedMeal?.meal_name))}</h4>
                  </Col>
                  <Col xs="auto" className="ml-auto">
                    {clinician.flags.clinician_allow_meal_edit && (
                      <span
                        className="edit-btn mx-3"
                        onClick={() => setDisplayMode('edit')}
                      >
                        <i className="fas fa-pen" />
                      </span>
                    )}
                    <span className="trash-btn mx-3">
                      <i
                        className="fas fa-trash"
                        onClick={handleDeleteMeal}
                      />
                    </span>
                    <span className="close-btn mx-3">
                      <i className="fas fa-times" onClick={props.onHide} />
                    </span>
                  </Col>
                </>
              )
              : (
                <>
                  <Col xs="auto">
                    <div className="d-flex" style={{ gap: '20px' }}>
                      <Controller
                        name="meal_date"
                        control={form.control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={i18n.language}
                          >
                            <DatePicker
                              className="datepicker"
                              inputVariant="outlined"
                              disableToolbar
                              format="YYYY-MM-DD"
                              label={t('Meal Date')}
                              variant="inline"
                              value={DateTime.fromISO(value).toJSDate()}
                              onChange={(event) => onChange(moment(event).format('YYYY-MM-DD'))}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                      />
                      <Controller
                        name="meal_time"
                        control={form.control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={i18n.language}
                          >
                            <TimePicker
                              {...form.register}
                              className="datepicker"
                              inputVariant="outlined"
                              format="hh:mm A"
                              label={t('Meal time')}
                              variant="inline"
                              value={DateTime.fromISO(value).toJSDate()}
                              onChange={(event) => onChange(moment(event).format('HH:mm:ss'))}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                      />
                    </div>
                    <select
                      className="w-30 form-control mt-3"
                      defaultValue={selectedMeal?.meal_name}
                      style={{ width: '220px', height: '40px' }}
                      {...form.register('meal_name')}
                    >
                      <option value="breakfast">
                        {t('Breakfast')}
                      </option>
                      <option value="lunch">
                        {t('Lunch')}
                      </option>
                      <option value="dinner">
                        {t('Dinner')}
                      </option>
                      <option value="snack">
                        {t('Snack')}
                      </option>
                    </select>
                  </Col>
                  <Col xs="auto" className="ml-auto">
                    <span
                      className="edit-btn mx-3"
                      onClick={form.handleSubmit(handleSaveMeal)}
                    >
                      {!saveMealReq.isPending && <i className="fas fa-check" />}
                      {saveMealReq.isPending && <i className="fas fa-spinner fa-spin" />}
                    </span>
                    <span className="close-btn mx-3" onClick={handleHide}>
                      <i className="fas fa-times" />
                    </span>
                  </Col>
                </>
              )}
          </Row>
          {mealPhotoShowLarge && (
            <Row>
              <Col>
                <OriginalImage
                  mealPhotoId={selectedMeal?.meal_photo_id}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setMealPhotoShowLarge(false)}
                />
              </Col>
            </Row>
          )}
          <div className="meals-container mt-4">
            {!editing
              ? (
                <>
                  <div className="mb-2">
                    <Trans count={selectedMeal?.meal_items.length}>
                      {{ count: selectedMeal?.meal_items.length }} <Trans>foods logged</Trans>
                    </Trans>
                  </div>
                  {selectedMeal?.meal_items.map((mealItem, idx) => {
                    const addonsStr = itemAddonsToStr(tAttr, mealItem);
                    return (
                      <Row key={mealItem.id || (mealItem as any)._rhf_id} className="mx-0">
                        <Col className="py-3 bx-1 d-flex justify-content-between" style={{ gap: '20px' }}>
                          <img src={mealItem.food_photo_url || MealThumbnailImage} width={80} height={80} />
                          <div className="flex-1">
                            <h5 className="mb-0">{titleCase(tAttr(mealItem, 'food_name'))}</h5>
                            <div>
                              <MealItemPercentEaten item={mealItem} />
                              {mealItem.servings} {tAttr(mealItem, 'serving_unit_label')}
                              {addonsStr && (' ' + t(`with`) + ' ' + addonsStr)}
                            </div>
                            {!!mealItem.note && <div>{t('Notes:') + ' ' + mealItem.note}</div>}
                          </div>
                          <MealItemMacros mealItem={mealItem} />
                        </Col>
                      </Row>
                    );
                  })}
                </>
              )
              : (
                mealItemsField.fields.map((mealItem, index) => (
                  <EditMealItemRow
                    key={index}
                    mealItem={mealItem}
                    onChange={(fields) => {
                      mealItemsField.update(index, {
                        ...mealItem,
                        ...fields,
                      });
                    }}
                    mealIndex={index}
                    register={(name, options) => form.register(`meal_items.${index}.${name}`, options)}
                    onRemove={() => mealItemsField.remove(index)}
                  />
                ))
              )}

            <Row className="mx-0" style={{ borderTop: '1px solid grey' }}>
              <Col className="py-3" style={{ paddingLeft: 0 }}>
                {(displayMode === 'edit' || displayMode === 'add') && (
                  <h5 className="mt-2">
                    <span
                      className="addMealBtn"
                      onClick={() => mealItemsField.append(getEmptyMealItem())}
                    >
                      <Trans>Add food</Trans> <i className="fas fa-plus mr-2" />
                    </span>
                  </h5>
                )}
              </Col>
            </Row>
          </div>

          {MacroSummaryCard}

          {!editing && patientFlags.flags.patient_show_dexcom_oauth && (
            <div>
              <div className="mt-3">
                <Trans>Glucose Profile (4hr after meal)</Trans>
              </div>
              <div style={{ width: '50%' }}>
                {selectedMeal && (
                  <CGMMealGlucoseChart
                    patient_id={patient.patient_id}
                    meal_date={selectedMeal.meal_date}
                    meal_time={selectedMeal.meal_time}
                    activityData={props.activityData}
                  />
                )}
              </div>
            </div>
          )}
          {selectedMeal
            && (
              <div className="mt-3">
                <PatientSurvey mealId={selectedMeal.id} />
              </div>
            )}
        </Modal.Body>
      </Modal>
    </FormProvider>
  );
};
