import { Grid, Input, InputAdornment, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import frCA from 'antd/es/locale/fr_CA';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { VictoryAxis, VictoryBar, VictoryStack } from 'victory';
import {
  useAgpData,
  useCgmRefetch,
  useGlucoseRanges,
  useGlucoseStats,
  useProcessedAgpData,
  useTimeInRangeData,
} from '../components/cgm/CGMDataService';
import { CGMGlucoseChart } from '../components/cgm/CGMGlucoseChart';
import {
  formatGlucoseValue,
  getDefaultGlucoseTargets,
  glucoseLabelsTr,
  GlucoseTargetName,
  GlucoseTargetsType,
} from '../components/cgm/cgmUtils';
import { LoadingSpinner } from '../components/loadingSpinner';
import { getRangePresets } from '../components/rangePicker';
import { MomentRangePicker } from '../components/rangePicker';
import { RxVictoryChart } from '../components/RxVictory';
import { config } from '../config';
import { useCurrentPatientData, useStore } from '../context';
import { Trans, useTranslation } from '../i18n';
import { parseLocalDateTime } from '../utils/formatDate';
import { formatNumber, useUpdatingState } from '../utils/formatters';

export const AgpDateRange = (props?: { dateSinceLocal?: string, dateUntilLocal?: string }) => {
  const { patient } = useStore();
  const { t, i18n } = useTranslation();
  const { flags } = useCurrentPatientData();
  const glucoseUnit = (flags?.patient_glucose_units_mmol_l ? 'mmol/L' : 'mg/dL') ?? 'mmol/L';

  const cgmRefetch = useCgmRefetch();
  const lastSync = cgmRefetch.refetchResult.find(c => c.provider === 'dexcom');
  const lastEvgTimestampStr = lastSync?.data_latest_timestamps?.['egvs'];
  const lastEvgTimestamp = !lastEvgTimestampStr ? null : DateTime.fromJSDate(parseLocalDateTime(lastEvgTimestampStr));

  const dateSince = props?.dateSinceLocal || lastEvgTimestamp?.minus({ days: 14 }).toISODate()
    || DateTime.now().minus({ days: 14 }).toISODate();
  const dateUntil = props?.dateUntilLocal || lastEvgTimestamp?.toISODate() || DateTime.now().toISODate();

  const [viewDates, setViewDates] = useState({
    patientId: patient.patient_id,
    dateSinceLocal: (config.IS_LOCAL || config.IS_DEV) ? '2022-12-17' : dateSince,
    dateUntilLocal: (config.IS_LOCAL || config.IS_DEV) ? '2022-12-31' : dateUntil,
  });
  const [agpDateFilter, setAgpDateFilter] = useState([
    moment(viewDates.dateSinceLocal).startOf('day'),
    moment(viewDates.dateUntilLocal).startOf('day'),
  ]);

  const agp = useAgpData(viewDates);
  const agpData = useProcessedAgpData(agp.glucosePeriodStats, viewDates.dateSinceLocal);

  const rangePresets = getRangePresets({ t, isAgp: true });
  const onRangeChange = (dates: [moment.Moment, moment.Moment]) => {
    if (dates) {
      const startDate = dates[0].clone().startOf('day');
      const endDate = dates[1].clone().startOf('day');
      setAgpDateFilter([startDate, endDate]);
      setViewDates({
        patientId: patient.patient_id,
        dateSinceLocal: startDate.toISOString().split('T')[0],
        dateUntilLocal: endDate.toISOString().split('T')[0],
      });
    }
  };

  const [showEditGlucoseRanges, setShowEditGlucoseRanges] = useState(false);

  const result = useMemo(
    () => {
      return (
        <div style={{ marginTop: '30px' }}>
          <div className="agpTitle">
            <i
              className="fas fa-pen patientMenuIcon"
              style={{ fontSize: '17px', paddingLeft: '2px' }}
              title={t('Edit Glucose Ranges')}
              onClick={() => setShowEditGlucoseRanges(true)}
            />
            <span>{t('AGP')}</span>
            <ConfigProvider locale={i18n.language === 'fr' ? frCA : enUS}>
              <MomentRangePicker
                presets={rangePresets}
                onChange={onRangeChange}
                value={[agpDateFilter[0], agpDateFilter[1]]}
              />
            </ConfigProvider>
          </div>
          {agp.query.isLoading
            ? <LoadingSpinner />
            : agp.query.isError
            ? (
              <div className="text-muted text-center">
                {/* eslint-disable i18next/no-literal-string */}
                {(agp.query.error?.['response']?.data?.message?.startsWith('no meals found for user')
                    || agp.query.error?.['response']?.data?.message?.startsWith('no data found'))
                  ? (
                    <div className="mt-2">
                      {t('No CGM data found for this date range')}
                    </div>
                  )
                  : (
                    <>
                      <Trans>Unexpected error loading AGP graph</Trans>: {'' + agp.query.error}
                    </>
                  )}
              </div>
            )
            : !agpData.length
            ? <div className="text-muted text-center">{t('No CGM data found for this date range')}</div>
            : (
              <>
                <div style={{ maxWidth: '900px', margin: '0 auto' }}>
                  <CGMGlucoseChart
                    data={agpData}
                    height={110}
                    width={240}
                    showValueAxis={true}
                    showTimeAxis={true}
                    agpSummary={true}
                    xTickFrequencyHours={1}
                    xDomainMS={[
                      moment(viewDates.dateSinceLocal).startOf('day').valueOf(),
                      moment(viewDates.dateSinceLocal).clone().endOf('day').valueOf(),
                    ]}
                    showTargetRange={true}
                  />
                </div>
                <Grid item md={12} sm={12}>
                  <GlucoseStats
                    dateSinceLocal={viewDates.dateSinceLocal}
                    dateUntilLocal={viewDates.dateUntilLocal}
                  />
                </Grid>
              </>
            )}
        </div>
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      agp.query,
      agp.glucosePeriodStats,
      viewDates.dateSinceLocal,
      viewDates.dateUntilLocal,
    ],
  );
  return (
    <>
      {result}
      <EditGlucoseRanges
        show={showEditGlucoseRanges}
        toggle={() => {
          setShowEditGlucoseRanges(!showEditGlucoseRanges);
        }}
      />
    </>
  );
};

const TimeInRange = (props: {
  dateSinceLocal: string,
  dateUntilLocal: string,
}) => {
  const { dateSinceLocal, dateUntilLocal } = props;
  const { t, i18n } = useTranslation();
  const { flags } = useCurrentPatientData();
  const glucoseUnits = (flags?.patient_glucose_units_mmol_l ? 'mmol/L' : 'mg/dL') ?? 'mmol/L';
  const timeInRangeQuery = useTimeInRangeData({ dateSinceLocal, dateUntilLocal });

  const barColors = ['#A42F33', '#DD4037', '#3FAD4A', '#FFF100', '#FAB812'];
  const styles = barColors.map((color) => {
    return { data: { fill: color, stroke: '#FFFFFF', strokeWidth: 0.5 } };
  });

  const getGlucoseValueStr = (value: number, showUnit?: boolean) => {
    const formattedValue = formatGlucoseValue(glucoseUnits, value, timeInRangeQuery.unit).value;
    const decimalPlaces = glucoseUnits === 'mmol/L' ? 1 : 0;

    const result = formatNumber(i18n.language, formattedValue, decimalPlaces);
    return showUnit ? `${result} ${glucoseUnits}` : result;
  };

  const getLabels = (glucoseRangesUpper: typeof timeInRangeQuery.chartData.glucoseRangesUpper) =>
    [
      {
        key: 'high_danger',
        label: t('Very High'),
        rangeLabel: '>' + getGlucoseValueStr(glucoseRangesUpper['high'], true),
      },
      {
        key: 'high',
        label: t('High'),
        rangeLabel: getGlucoseValueStr(glucoseRangesUpper['target'], false) + ' - '
          + getGlucoseValueStr(glucoseRangesUpper['high'], true),
      },
      {
        key: 'target',
        label: t('Target'),
        rangeLabel: getGlucoseValueStr(glucoseRangesUpper['low'], false) + ' - '
          + getGlucoseValueStr(glucoseRangesUpper['target'], true),
      },
      {
        key: 'low',
        label: t('Low'),
        rangeLabel: getGlucoseValueStr(glucoseRangesUpper['low_danger']) + ' - '
          + getGlucoseValueStr(glucoseRangesUpper['low'], true),
      },
      {
        key: 'low_danger',
        label: t('Very Low'),
        rangeLabel: '<' + getGlucoseValueStr(glucoseRangesUpper['low_danger'], true),
      },
    ] as const;

  const getBelowThresholdCount = (threshold: number) => {
    return Object.keys(timeInRangeQuery.minuteData).reduce((acc, curr) => {
      const percent = timeInRangeQuery.minuteData[curr] / timeInRangeQuery.totalMinutesActive;
      if (percent < threshold) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  const getHeights = (opts: { heightThreshold: number, minHeight: number, totalHeight: number }) => {
    const { heightThreshold, minHeight, totalHeight } = opts;
    return Object.keys(timeInRangeQuery.minuteData).map(key => {
      const percent = timeInRangeQuery.minuteData[key] / timeInRangeQuery.totalMinutesActive;
      if (percent < heightThreshold) {
        return minHeight;
      }
      return percent * (totalHeight - minHeight * getBelowThresholdCount(heightThreshold));
    });
  };

  const labels = timeInRangeQuery.isGlucosePeriodStats && timeInRangeQuery.chartData.glucoseRangesUpper
    && getLabels(timeInRangeQuery.chartData.glucoseRangesUpper);
  const barHeights = timeInRangeQuery.isGlucosePeriodStats && timeInRangeQuery.minuteData
    && getHeights({ heightThreshold: 0.05, minHeight: 5, totalHeight: 130 });
  const labelHeights = timeInRangeQuery.isGlucosePeriodStats && timeInRangeQuery.minuteData
    && getHeights({ heightThreshold: 0.09, minHeight: 22, totalHeight: 220 }).reverse();

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <svg width="100" height="400" viewBox="0 0 100 400">
        <RxVictoryChart standalone={false}>
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: 'transparent' },
              ticks: { stroke: 'transparent' },
              tickLabels: { fill: 'transparent' },
            }}
          />
          <VictoryStack style={{ data: { width: 30 } }}>
            {!!barHeights.length && (barHeights.map((d) => [{ x: 0, y: d }]).map((d, idx) => (
              <VictoryBar
                key={idx}
                style={styles[idx]}
                data={d}
                cornerRadius={{
                  bottomLeft: (idx === 0 ? 5 : 0),
                  bottomRight: (idx === 0 ? 5 : 0),
                  topLeft: (idx === 4 ? 5 : 0),
                  topRight: (idx === 4 ? 5 : 0),
                }}
              />
            )))}
          </VictoryStack>
        </RxVictoryChart>
      </svg>
      <div style={{ marginTop: '30px', marginLeft: '-20px', width: '300px' }}>
        <div className="timeInRangeBox">
          {!!labels.length
            && (labels.map((row, idx) => (
              <div key={idx} className="timeInRangeRowContainer" style={{ height: `${labelHeights[idx]}px` }}>
                <div className="timeInRangeRow">
                  <div className="timeInRangeLabelGroup">
                    <span className="timeInRangeLabel">{row.label}</span>
                    <span className="timeInRangeSubLabel">{row.rangeLabel}</span>
                  </div>
                  <div className="timeInRangeLabelGroup">
                    <span className="timeInRangeLabel">{timeInRangeQuery.percentData[row.key]}</span>
                    <span className="timeInRangeSubLabel">{'(' + timeInRangeQuery.hrMinData[row.key] + ')'}</span>
                  </div>
                </div>
              </div>
            )))}
        </div>
      </div>
    </div>
  );
};

const GlucoseStats = (props: {
  dateSinceLocal: string,
  dateUntilLocal: string,
}) => {
  const { dateSinceLocal, dateUntilLocal } = props;
  const { flags } = useCurrentPatientData();
  const { t, i18n } = useTranslation();
  const glucoseUnits = (flags?.patient_glucose_units_mmol_l ? 'mmol/L' : 'mg/dL') ?? 'mmol/L';
  const glucoseStatsQuery = useGlucoseStats({ dateSinceLocal, dateUntilLocal });

  const rows = [
    {
      name: t('Number of Days'),
      value: glucoseStatsQuery.numDays,
      unit: t('days'),
      label: `${props.dateSinceLocal} - ${props.dateUntilLocal}`,
    },
    {
      name: t('% Time CGM is Active'),
      value: formatNumber(i18n.language, glucoseStatsQuery.percentTimeActive, 1),
      unit: '%',
    },
    {
      name: t('Average Glucose'),
      value: formatNumber(
        i18n.language,
        formatGlucoseValue(glucoseUnits, glucoseStatsQuery.avgGlucose, glucoseStatsQuery.unit).value,
        1,
      ),
      unit: glucoseUnits,
    },
    {
      name: t('GMI'),
      value: formatNumber(i18n.language, glucoseStatsQuery.gmiMgDl, 1),
      unit: '%',
      label: t('Glucose Management Indicator'),
    },
    {
      name: t('Glucose Variability'),
      value: formatNumber(i18n.language, glucoseStatsQuery.cv, 1),
      unit: '%',
      label: t('Defined as a percent coefficient of variation (%CV); ≤ target 36%.'),
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '-30px',
        maxWidth: '1000px',
        margin: '0 auto',
      }}
    >
      <div style={{ flexBasis: '100%' }}>
        <div className="summaryTitle">
          <Trans>Glucose Statistics</Trans>
        </div>
        <Table style={{ width: '60%', margin: '0 auto' }}>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell className="summaryText">
                  {row.name} {row.label && (
                    <div style={{ fontSize: '10px', textAlign: 'left' }}>
                      {row.label}
                    </div>
                  )}
                </TableCell>
                <TableCell className="summaryText">
                  {row.value} {row.unit}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div style={{ flexBasis: '75%' }}>
        <div className="summaryTitle">
          <Trans>Time in ranges</Trans>
        </div>
        <TimeInRange dateSinceLocal={dateSinceLocal} dateUntilLocal={dateUntilLocal} />
      </div>
    </div>
  );
};

export const EditGlucoseRanges = (props: { show: boolean, toggle: () => void }) => {
  const { flags } = useCurrentPatientData();
  const { t } = useTranslation();
  const glucoseRangeQuery = useGlucoseRanges();

  const glucoseUnits = flags.patient_glucose_units_mmol_l ? 'mmol/L' : 'mg/dL';

  const currentFormState = useMemo(() => glucoseRangeQuery.data, [glucoseRangeQuery.data]);

  const [initialFormState, setInitialFormState] = useUpdatingState<GlucoseTargetsType>(
    currentFormState,
    getDefaultGlucoseTargets(glucoseUnits),
  );
  const [formState, setFormState] = useState<Partial<GlucoseTargetsType>>({});
  const [error, setError] = useState<string | null>(null);

  const handleExit = () => props.toggle();

  const onSubmit = () => {
    if (_.isEmpty(formState)) {
      glucoseRangeQuery.resetGlucoseRanges();
      handleExit();
      return;
    }

    const updatedState = Object.entries(formState).reduce((acc, [key, value]) => {
      acc[key] = {
        ...value,
        ...(value.mmMolLower ? { mmMolLower: value.mmMolLower } : {}),
        ...(value.mmMolUpper ? { mmMolUpper: value.mmMolUpper } : {}),
      };
      return acc;
    }, {} as GlucoseTargetsType);
    glucoseRangeQuery.updateGlucoseRanges(updatedState);

    setFormState({});
    handleExit();
  };

  const updateFormState = (targetName: GlucoseTargetName, value: number) => {
    if (value < 0 || value == null) {
      setError(t('Value must be a number greater than 0'));
      return;
    }
    if (error) {
      setError(null);
    }
    setFormState(prev => {
      const updatedState = { ...prev };
      const targetNames = Object.keys(initialFormState) as GlucoseTargetName[];
      const currentIndex = targetNames.indexOf(targetName);
      updatedState[targetName] = {
        ...updatedState[targetName],
        valueUpper: value,
        mmMolUpper: _.round(
          formatGlucoseValue('mmol/L', value, glucoseUnits).value,
          1,
        ),
      };
      if (currentIndex < targetNames.length - 1) {
        const nextTargetName = targetNames[currentIndex + 1];
        updatedState[nextTargetName] = {
          ...updatedState[nextTargetName],
          valueLower: value,
          mmMolLower: _.round(
            formatGlucoseValue('mmol/L', value, glucoseUnits).value,
            1,
          ),
        };

        const allTargets = _.merge({}, initialFormState, updatedState);
        if (
          Object.values(allTargets).some(target => target.valueLower > target.valueUpper)
        ) {
          setError(t('Lower bound must be less than upper bound'));
        }
      }
      return updatedState;
    });
  };

  const handleReset = () => {
    setFormState({});
    setInitialFormState(getDefaultGlucoseTargets(glucoseUnits));
    setError(null);
  };

  return (
    <MDBContainer>
      <MDBModal backdrop={false} isOpen={props.show} toggle={props.toggle} centered size="lg">
        <MDBModalHeader toggle={props.toggle}>
          <i className="fas fa- margin-right-s" />
          <Trans>Customize Patient Glucose Ranges</Trans>
        </MDBModalHeader>
        <MDBModalBody>
          <div style={{ margin: '0 20px 40px', padding: '0 20px' }}>
            <Table size="medium">
              <TableBody>
                <TableRow style={{ paddingLeft: '20px' }}>
                  <TableCell component="th" style={{ fontSize: '16px' }} align="center">
                    <Trans>Range</Trans>
                  </TableCell>
                  <TableCell
                    component="th"
                    style={{ fontSize: '16px' }}
                    align="center"
                  >
                    {t(`Value (${glucoseUnits})`)}
                    <span style={{ marginLeft: '20px' }}>
                      <button onClick={() => handleReset()}>
                        <Trans>Reset</Trans>
                      </button>
                    </span>
                  </TableCell>
                </TableRow>
                {(Object.keys(initialFormState) as GlucoseTargetName[]).map((targetName, i) => (
                  <TableRow key={i}>
                    <TableCell style={{ fontSize: '16px' }} align="center">
                      {glucoseLabelsTr(t)[initialFormState[targetName].label] || initialFormState[targetName].label}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: '16px' }}
                      align="center"
                    >
                      {formState[targetName]?.valueLower ?? initialFormState[targetName].valueLower} –
                      <Input
                        endAdornment={
                          <InputAdornment disableTypography position="end">
                            {glucoseUnits}
                          </InputAdornment>
                        }
                        value={formState[targetName]?.valueUpper ?? initialFormState[targetName].valueUpper}
                        onChange={e => updateFormState(targetName, Number(e.target.value))}
                        style={{ width: '110px', padding: '0 5', margin: '0 10px' }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          {error && <div style={{ color: 'red', margin: '0 20px 20px' }}>{error}</div>}
          {glucoseRangeQuery.isError && (
            <div style={{ color: 'red', margin: '10px 20px 20px' }}>
              <Trans>There was an unexpected error getting the glucose ranges.</Trans>
            </div>
          )}

          <p className="margin-bot-l">
            <Button
              block
              onClick={onSubmit}
              disabled={!!error || glucoseRangeQuery.isLoading || glucoseRangeQuery.isError}
            >
              <Trans>Save</Trans>
            </Button>
          </p>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
