import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useMemo, useRef, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { VictoryAxis, VictoryBar, VictoryLine, VictoryStack, VictoryTooltip, VictoryVoronoiContainer } from 'victory';
import { reportingApi } from '../api';
import {
  ClinicianPatientsTableResponseRow,
  GoalExtendedWeeklyReportResponse,
  MealResponse,
  PatientNutritionDetailsTableRow,
} from '../api/generated';
import { useStore } from '../context';
import { useTranslation } from '../i18n';
import { nutrientGetDef } from '../pages/nutrients';
import { renderGoalText } from '../pages/patient-goals';
import { ActivityFeedItem } from '../pages/patient-logV2';
import { NutrientRowDataType } from '../pages/patient-nutrient-new';
import { getMealResponseByMealId, getMealsByDateAndName } from './helpers/mealHelpers';
import '../pages/patient-nutrient.scss';
import { useGoals } from '../services/goal';
import { idToStr } from '../utils/idStr';
import { CircleDot } from './circleDot';
import { FilterType } from './foodFilterNew';
import { LabelG } from './label';
import { LoadingSpinner } from './loadingSpinner';
import { MealCard } from './mealCard';
import { RxErrorBoundary } from './RxErrorBoundary';
import { RxVictoryChart } from './RxVictory';
import { ShowMealV2 } from './showMealV2';

export type mealCardNutrientValueType = { [nutrientValue: string]: number, meal_id: number };
export type barAmount = { x: Date, y: number, label: string };

const barChartColors = {
  breakfast: '#D19A66',
  lunch: '#FFD700',
  dinner: '#61AFEF',
  snack: '#C678DD',
};

export const getMealItemsNutrientSum = (meal: MealResponse, selectedNutrient: NutrientRowDataType) => {
  return _.sum(meal.meal_items.map(item => item[selectedNutrient.field] || undefined));
};

export const useNutrientDetailsData = (
  filter: FilterType,
  patient: ClinicianPatientsTableResponseRow,
) => {
  const formattedDateRanges = filter.dateRanges.map(range => ({ gte: range[0], lte: range[1] }));

  return useQuery(['nutrient-query-by-meal-id', filter, patient.patient_id], async () => {
    const nutrients = await reportingApi.appApiPatientReportsApiGetPatientNutritionDetailsTable({
      patient_id: patient.patient_id,
      group_by: 'meal_id',
      filter: JSON.stringify({
        meal_date: {
          or: formattedDateRanges,
        },
      }),
    });
    return nutrients.data;
  }, {
    staleTime: 1000 * 60,
    refetchOnMount: false,
  });
};

export const ShowNutrientDetails = (props: {
  filter: FilterType,
  enumeratedDays: moment.Moment[],
  columnHeaders: string[],
  selectedNutrient: NutrientRowDataType,
  filteredFeedEntries: ActivityFeedItem[],
  rdiValue: string,
  onHide: () => void,
}) => {
  const { filter, enumeratedDays, columnHeaders, selectedNutrient, filteredFeedEntries, rdiValue, onHide } = props;
  const { t } = useTranslation();
  const { patient } = useStore();
  const { goals } = useGoals();

  const headers = columnHeaders.slice(1).map(header => {
    return { color: barChartColors?.[header.toLowerCase()] as string, label: header };
  });
  const nutrientDef = nutrientGetDef(selectedNutrient.field as keyof PatientNutritionDetailsTableRow);
  const nutrientGoal = goals?.find(goal => goal?.target_name === selectedNutrient.field);
  const enumeratedDaysJS = enumeratedDays.map((day) => day.clone().startOf('day').toDate()).reverse();

  const [showMeals, setShowMeals] = useState<mealCardNutrientValueType[] | null>(null);
  const [selectedMeal, setSelectedMeal] = useState<MealResponse | null>(null);

  const getFormattedTableValue = (mealValue: string) =>
    mealValue === '–'
      ? mealValue
      : nutrientDef.suffix === 'kcal'
      ? mealValue + ' ' + nutrientDef.suffix
      : mealValue + nutrientDef.suffix;

  const nutrientDetailsQuery = useNutrientDetailsData(filter, patient);

  const nutrientDetailsData = useMemo(() => {
    const mealsDescByNutrient: mealCardNutrientValueType[] = nutrientDetailsQuery.data?.rows
      ?.map(meal => {
        return { [selectedNutrient.field]: meal[selectedNutrient.field].sum, meal_id: meal.meal_id };
      })
      ?.sort((a, b) => {
        return b[selectedNutrient.field] - a[selectedNutrient.field];
      })
      .filter(meal => {
        return getMealResponseByMealId(meal.meal_id, filteredFeedEntries)?.meal_items.some(item => {
          return item.food_name !== 'awaiting verification...'
            && !item.custom_item
            && selectedNutrient.field !== 'meal_date_count';
        });
      });

    const chartData: { [mealName: string]: barAmount[] } = {
      breakfast: [],
      lunch: [],
      dinner: [],
      snack: [],
    };

    nutrientDetailsQuery.data?.rows
      ?.filter(meal => {
        return getMealResponseByMealId(meal.meal_id, filteredFeedEntries)?.meal_items.some(item => {
          return item.food_name !== 'awaiting verification...'
            && !item.custom_item;
        });
      })
      ?.map(meal => {
        const mealResponse = getMealResponseByMealId(meal.meal_id, filteredFeedEntries);
        const dateX = DateTime.fromISO(mealResponse.meal_date).toJSDate();
        const valueY = selectedNutrient.field === 'meal_date_count'
          ? meal[selectedNutrient.field]
          : meal[selectedNutrient.field].sum;

        const existingMeal = chartData[mealResponse.meal_name].find(entry =>
          entry.x.toISOString() === dateX.toISOString()
        );
        if (existingMeal) {
          existingMeal.y += valueY;
          existingMeal.label = mealResponse.meal_name + '\n' + existingMeal.y;
          return;
        }
        chartData[mealResponse.meal_name].push({ x: dateX, y: valueY, label: mealResponse.meal_name + '\n' + valueY });
      });
    Object.values(chartData).forEach((mealArr) => {
      // add empty data for days with no meals
      if (!mealArr.length) {
        mealArr.push({ x: new Date(0), y: 0, label: '' });
      }
      mealArr.sort((a, b) => a.x.getTime() - b.x.getTime());
    });

    return { mealsDescByNutrient, chartData };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nutrientDetailsQuery.data, selectedNutrient.field, filteredFeedEntries]);

  const getMatchingMeals = (date: string, mealName: string) => {
    const mealResponses = getMealsByDateAndName(date, mealName, filteredFeedEntries);
    const matchingMeals = mealResponses.map(meal => {
      return nutrientDetailsData.mealsDescByNutrient.find(item => item.meal_id === meal.id);
    });
    return matchingMeals?.filter(x => !!x);
  };

  const handleShowMeals = (mealArr: barAmount[] | null, date?: Date) => {
    if (!mealArr || selectedNutrient.field === 'meal_date_count') {
      setShowMeals(null);
      return;
    }
    // eslint-disable-next-line i18next/no-literal-string
    const dateStr = date.toISOString().split('T')[0];
    const label = mealArr.find(meal => meal.x.toISOString() === date.toISOString())?.label;
    const mealName = label?.split('\n')[0];
    const meals = getMatchingMeals(dateStr, mealName);
    if (meals) {
      setShowMeals(meals);
    }
  };

  return (
    <div style={{ width: '100%', minWidth: '400px' }}>
      <Modal.Header closeButton onHide={onHide} style={{ borderBottom: 'none', paddingBottom: '8px' }}>
        <LabelG style={{ fontWeight: 600, letterSpacing: '.1rem' }}>
          {t('{{nutrient}} details (average {{suffix}} per meal)', {
            nutrient: nutrientDef.label,
            suffix: nutrientDef.suffix,
          })}
        </LabelG>
      </Modal.Header>

      {nutrientGoal
        && (
          <div style={{ paddingLeft: '16px', paddingBottom: '10px', fontSize: '14.4px' }}>
            <Trans>Prescription</Trans>:{' '}
            <span style={{ textTransform: 'lowercase' }}>
              {`${renderGoalText(nutrientGoal, t)} ${nutrientGoal.target_value}${nutrientGoal.target_unit}
            ${t('per')} ${nutrientGoal.period}`}
            </span>
            <span style={{ textDecoration: 'underline', paddingLeft: '5px', fontSize: '12.5px' }}>
              <Link to={`/patient/${idToStr(patient.patient_id)}/prescriptions`}>
                <Trans>edit</Trans>
              </Link>
            </span>
          </div>
        )}

      <Modal.Body style={{ paddingTop: 0 }}>
        <Table className="border-bottom table-sm">
          <tbody>
            <tr>
              {headers.map(({ color, label }, idx) => (
                <th key={idx}>
                  <span key={label} className="d-flex align-items-center justify-content-start mr-2">
                    {label}
                    <CircleDot color={color} size="10px" />
                  </span>
                </th>
              ))}
            </tr>
            <tr>
              <td>{getFormattedTableValue(selectedNutrient.breakfast)}</td>
              <td>{getFormattedTableValue(selectedNutrient.lunch)}</td>
              <td>{getFormattedTableValue(selectedNutrient.dinner)}</td>
              <td>{getFormattedTableValue(selectedNutrient.snack)}</td>
              <td>{getFormattedTableValue(selectedNutrient.total)}</td>
              <td>{getFormattedTableValue(rdiValue)}</td>
            </tr>
          </tbody>
        </Table>

        <RxErrorBoundary>
          <div>
            {nutrientDetailsQuery.isLoading
              ? <LoadingSpinner />
              : nutrientDetailsQuery.isError
              ? <div>{t('Unexpected error loading nutrient bar graph')}</div>
              // check for no data case
              : Object.values(nutrientDetailsData.chartData).map(arr => !arr[0].label && !arr[0].y).every(Boolean)
              ? (
                <div style={{ fontSize: '14.4px', textAlign: 'center', marginTop: '40px' }}>
                  {t('No meals logged between {{start}} and {{end}}', {
                    start: filter.rangePickerValue[0].clone().startOf('day').toISOString().split('T')[0],
                    end: filter.rangePickerValue[1].clone().startOf('day').toISOString().split('T')[0],
                  })}
                </div>
              )
              : (
                <div style={{ marginTop: '-10px', paddingBottom: '20px' }}>
                  <NutrientBarChart
                    data={nutrientDetailsData.chartData}
                    nutrientGoal={nutrientGoal?.period === 'day' ? nutrientGoal : null}
                    enumeratedDaysJS={enumeratedDaysJS}
                    chartWidth={600}
                    handleShowMeals={handleShowMeals}
                  />
                </div>
              )}
          </div>
        </RxErrorBoundary>

        <MaxHeightContainer>
          {showMeals
            ? (
              <div style={{ paddingLeft: '16px', display: 'flex', flexWrap: 'wrap', maxWidth: '700px' }}>
                {showMeals.map((item, idx) => (
                  <MealCard
                    key={idx}
                    nutrientVal={item}
                    meal={getMealResponseByMealId(item?.meal_id, filteredFeedEntries)}
                    selectedNutrient={selectedNutrient}
                    width="300px"
                  />
                ))}
              </div>
            )
            : (nutrientDetailsData.mealsDescByNutrient?.length > 0
              && (
                <div style={{ paddingLeft: '16px' }}>
                  <div style={{ paddingBottom: '8px' }}>
                    {t('Top sources of {{ nutrient }}', { nutrient: selectedNutrient.label.split('(')[0] })}
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '700px' }}>
                    {nutrientDetailsData.mealsDescByNutrient?.slice(0, 10).map((item, idx) => (
                      <div
                        key={idx}
                        onClick={() => setSelectedMeal(getMealResponseByMealId(item.meal_id, filteredFeedEntries))}
                      >
                        <MealCard
                          nutrientVal={item}
                          meal={getMealResponseByMealId(item?.meal_id, filteredFeedEntries)}
                          selectedNutrient={selectedNutrient}
                          width="300px"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
        </MaxHeightContainer>
      </Modal.Body>

      {!!selectedMeal && (
        <ShowMealV2
          selectedMeal={selectedMeal}
          activityData={filteredFeedEntries}
          onHide={() => setSelectedMeal(null)}
          initialMode="view"
        />
      )}
    </div>
  );
};

const getMaxYValueByDate = (data: { [mealName: string]: barAmount[] }) => {
  let maxYValue = 0;
  const yValuesByDate: { [date: string]: number } = {};

  Object.values(data).forEach((mealNameData) => {
    mealNameData.forEach((meal) => {
      // eslint-disable-next-line i18next/no-literal-string
      const dateString = meal.x.toISOString().split('T')[0];
      yValuesByDate[dateString] = (yValuesByDate[dateString] || 0) + meal.y;
      maxYValue = Math.max(maxYValue, yValuesByDate[dateString]);
    });
  });
  return maxYValue;
};

export const NutrientBarChart = (props: {
  data: { [mealName: string]: barAmount[] },
  nutrientGoal: GoalExtendedWeeklyReportResponse,
  enumeratedDaysJS: Date[],
  chartWidth: number,
  handleShowMeals: (mealArr: barAmount[] | null, date?: Date) => void,
}) => {
  const { data, nutrientGoal, enumeratedDaysJS, handleShowMeals } = props;

  const [xMinDateJS, xMaxDateJS] = [enumeratedDaysJS[0], enumeratedDaysJS[enumeratedDaysJS.length - 1]];
  // extend y axis to 1.5 times the max value
  const maxYValue = getMaxYValueByDate(data);
  const [yMin, yMax] = nutrientGoal && (nutrientGoal.target_value > maxYValue)
    ? [0, Math.round(nutrientGoal?.target_value * 1.5)]
    // prevent both yMin and yMax from being the same value
    : [0, Math.round(maxYValue * 1.5) ?? 1];

  const xTickData = {
    xTickValues: enumeratedDaysJS,
    xTickCount: enumeratedDaysJS.length,
    xTickFormat: (date: Date) =>
      enumeratedDaysJS.length < 25
        ? `${moment(date).format('ddd')}\n ${moment(date).format('DD')}`
        : enumeratedDaysJS.length < 40
        ? `${moment(date).format('dd')}\n ${moment(date).format('DD')}`
        : `${moment(date).format('DD')}`,
  };

  const goalLineData = {
    value: () => nutrientGoal?.target_value,
    labels: () => `${nutrientGoal?.target_value}${nutrientGoal?.target_unit} / ${nutrientGoal?.period}`,
  };

  return (
    <RxVictoryChart
      width={props.chartWidth}
      containerComponent={
        <VictoryVoronoiContainer
          labels={goalLineData.labels}
          voronoiBlacklist={['bar']}
          radius={10}
        />
      }
      domainPadding={{ x: 20 }}
      domain={{
        x: [xMinDateJS, xMaxDateJS],
        y: [yMin, yMax],
      }}
    >
      <VictoryAxis
        scale="time"
        tickCount={xTickData.xTickCount}
        tickValues={xTickData.xTickValues}
        tickFormat={xTickData.xTickFormat}
        style={{
          axis: { stroke: '#A0A6A9', strokeWidth: '1' },
          tickLabels: {
            fill: '#A0A6A9',
            fontSize: enumeratedDaysJS.length < 14 ? '14px' : enumeratedDaysJS.length < 40 ? '10px' : '6px',
          },
          grid: { stroke: '#ECEFF1', strokeWidth: 1.5, strokeDasharray: '5, 5' },
        }}
      />
      <VictoryAxis
        dependentAxis
        style={{
          axis: { stroke: 'transparent', strokeWidth: '1' },
          tickLabels: { fill: '#A0A6A9' },
          ticks: { stroke: 'transparent' },
          grid: { stroke: '#ECEFF1', strokeWidth: 1.5, strokeDasharray: '5, 5' },
        }}
      />
      <VictoryStack colorScale={Object.values(barChartColors)}>
        {Object.keys(data).map((mealName, idx) => {
          return (
            <VictoryBar
              name="bar"
              key={idx}
              data={data[mealName]}
              labelComponent={<VictoryTooltip />}
              barWidth={36 / enumeratedDaysJS.length * 8}
              animate={{ onLoad: { duration: 700 } }}
              events={[{
                target: 'data',
                eventHandlers: {
                  onMouseOver: () => [{
                    target: 'data',
                    mutation: (d) => handleShowMeals(data[mealName], d.datum.x),
                  }],
                  onMouseOut: () => [{
                    target: 'data',
                    mutation: () => handleShowMeals(null),
                  }],
                },
              }]}
            />
          );
        })}
      </VictoryStack>
      {!!nutrientGoal && (
        <VictoryLine
          name="line"
          y={goalLineData.value}
          style={{
            data: {
              stroke: nutrientGoal.change === 'increase' ? '#98C379' : '#E06C75',
              strokeWidth: 2,
              strokeDasharray: '5, 5',
            },
          }}
        />
      )}
    </RxVictoryChart>
  );
};

export const MaxHeightContainer = (props: { children: React.ReactNode }) => {
  const maxHeight = useRef(0);
  const childRef = useRef<HTMLDivElement>(null);
  maxHeight.current = Math.max(maxHeight.current, childRef.current?.getBoundingClientRect().height || 0);

  return (
    <div style={{ height: maxHeight.current }}>
      <div ref={childRef}>
        {props.children}
      </div>
    </div>
  );
};
