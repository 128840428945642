import * as d3Array from 'd3-array';
import { t } from 'i18next';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { VictoryArea, VictoryAxis, VictoryGroup, VictoryLine, VictoryScatter } from 'victory';
import { useCurrentPatientData } from '../../context';
import { activityFeedItemsToCgmSummaryChartPoints } from '../../pages/patient-logV2';
import MealImage from '../../theme/icons/meal.svg';
import { RxVictoryChart, victoryComponent, VictoryInjectedProps } from '../RxVictory';
import { useCgmData, useGlucoseRanges, useProcessedCgmData } from './CGMDataService';
import {
  CgmMealAndPatient,
  cgmStyles,
  formatGlucoseValue,
  getCgmPostPrandialTimeRange,
  getGlucoseChartTargetRange,
  glucoseChartLowerBound,
  glucoseChartUpperBound,
  GlucoseTargetsType,
  GlucoseUnit,
} from './cgmUtils';

export type CGMReportMarkerPoint = {
  icon: string,
  color: string,
  xTimestampMS: Date,
};

export type MarkerWithYValue = {
  icon: string,
  color: string,
  xTimestampMS: Date,
  value: number,
};

const PointMarker = (props) => {
  const { x, y, datum } = props;

  const icon: CGMReportMarkerPoint = datum;
  const size = 5.5;

  if (!x || !y) {
    return null;
  }

  // TODO: make this a proper svg icon component
  return (
    <g transform={`translate(${x - size / 2}, ${y})`} style={{ position: 'relative' }}>
      <circle x={size / 2} y={size / 2} r={size - 1} fill={icon.color} />
      <image href={icon.icon} width={size} height={size} transform={`translate(-${size / 2}, -${size / 2})`} />
    </g>
  );
};

const mkRectProps = (yMin: number, yMax: number, yScale: any) => {
  const pos = {
    x1: 0,
    x2: 500,
    y1: yScale(yMax),
    y2: yScale(yMin),
  };

  return {
    x: pos.x1,
    width: pos.x2 - pos.x1,
    y: pos.y1,
    height: pos.y2 - pos.y1,
  };
};

const RangeClipPaths = victoryComponent((
  props: { glucoseUnits: GlucoseUnit, glucoseRanges: GlucoseTargetsType } & VictoryInjectedProps,
) => {
  console.log('RangeClipPaths: props:', props);
  return (
    <defs>
      {Object.entries(props.glucoseRanges).map(([targetName, target], i) => (
        <clipPath
          key={`${targetName}-${props.domain.y[0]}-${props.domain.y[1]}`}
          id={`glucose-target-${targetName}-clip-${props.domain.y[0]}-${props.domain.y[1]}`}
        >
          <rect
            {...mkRectProps(
              formatGlucoseValue(props.glucoseUnits, target.mmMolLower, 'mmol/L').value,
              formatGlucoseValue(props.glucoseUnits, target.mmMolUpper, 'mmol/L').value,
              props.scale.y,
            )}
          />
        </clipPath>
      ))}
    </defs>
  );
});

const roundTimestampToMinute = (timestamp: number, toMinutes: number) => {
  const ms = toMinutes * 60 * 1000;
  return Math.floor(timestamp / ms) * ms;
};

export const CGMMealGlucoseChart = (props: CgmMealAndPatient) => {
  const cgmTimeRange = getCgmPostPrandialTimeRange(props);
  const { t } = useTranslation();
  const { glucoseData } = useCgmData(cgmTimeRange);
  const cgmData = useProcessedCgmData(glucoseData);
  const mealTime = cgmTimeRange.mealTime;
  const mealValue = _.find(cgmData, (d) => d.xTimestampMS >= mealTime.getDate());

  const markers = props.activityData
    ? activityFeedItemsToCgmSummaryChartPoints(t, props.activityData)
    : mealValue
    ? [{
      icon: MealImage,
      color: '#36C2B4',
      value: mealValue.value + (mealValue.value < 5 ? 3 : -1),
      xTimestampMS: mealTime,
    }]
    : [];

  if (cgmData.length == 0) {
    return (
      <div style={{ fontStyle: 'italic', opacity: 0.75 }}>
        <Trans>CGM data not available</Trans>
      </div>
    );
  }

  return (
    <CGMGlucoseChart
      width={240}
      data={cgmData}
      height={110}
      markers={markers}
      showTimeAxis={true}
      showValueAxis={true}
      xTickFrequencyHours={1}
      // timesToHighlightMS={[mealTime.getTime()]}
      xDomainMS={[
        mealTime.getTime() - 30 * 60 * 1000,
        mealTime.getTime() + 4 * 60 * 60 * 1000,
      ]}
      showTargetRange={true}
    />
  );
};

const groupOverlappingMarkers = (markers: MarkerWithYValue[]) => {
  const res: Array<MarkerWithYValue[]> = [];
  const markerWidthMS = 30 * 60 * 1000;
  const markersArr = markers.slice().reverse(); // order markers chronologically
  let lastGroup: MarkerWithYValue[] = [];
  let lastXTimestampMS = 0;

  markersArr.forEach(marker => {
    const xTimestampMS = marker.xTimestampMS.getTime();

    if (xTimestampMS > lastXTimestampMS + markerWidthMS) {
      res.push(lastGroup);
      lastGroup = [];
    }
    lastGroup.push(marker);
    lastXTimestampMS = xTimestampMS;
  });
  res.push(lastGroup);
  return res.filter(group => group.length >= 1);
};

const fixOverlapsInGroup = (group: MarkerWithYValue[], yDomain: [number, number]): MarkerWithYValue[] => {
  const middleIndex = Math.floor(group.length / 2);
  let minY = d3Array.min(group, d => d.value);
  let maxY = d3Array.max(group, d => d.value);
  let spaceMultiplier = (yDomain[1] - yDomain[0]) / 8.5;
  let res: MarkerWithYValue[] = [];

  do {
    res = group.map((icon, idx) => {
      const offset = idx === middleIndex ? 0 : middleIndex - idx;
      const newIconY = icon.value + offset * spaceMultiplier;
      return {
        ...icon,
        value: Math.round((newIconY) * 10) / 10,
      };
    });
    spaceMultiplier -= 0.5;
    minY = d3Array.min(res, d => d.value);
    maxY = d3Array.max(res, d => d.value);
  } while (minY < yDomain[0] && maxY > yDomain[1]);

  return res;
};

const moveMarkersAtLeftEdge = (markers: MarkerWithYValue[], showTimeAxis: boolean) => {
  return markers.map(marker => {
    const xTimestampMoment = moment(marker.xTimestampMS);
    const hour = xTimestampMoment.clone().hour();
    const shiftByHour = showTimeAxis ? 1 : 4;
    const graphEdgeHour = xTimestampMoment.clone().startOf('day').add(shiftByHour, 'hour');

    if (hour >= 0 && hour < shiftByHour) {
      const difference = graphEdgeHour.diff(xTimestampMoment);
      return {
        ...marker,
        xTimestampMS: new Date(marker.xTimestampMS.getTime() + difference),
      };
    }
    return marker;
  });
};

const fixMarkerOverlaps = (
  markers: MarkerWithYValue[],
  yDomain: [number, number],
  showTimeAxis: boolean,
): MarkerWithYValue[] => {
  const overlappingGroups = groupOverlappingMarkers(markers);
  const res: MarkerWithYValue[] = [];

  overlappingGroups.forEach(group => {
    res.push(...fixOverlapsInGroup(group, yDomain));
  });
  return moveMarkersAtLeftEdge(res, showTimeAxis);
};

export const CGMGlucoseChart = (props: {
  data: Array<{
    value: number,
    p5?: number,
    p25?: number,
    p75?: number,
    p95?: number,
    xTimestampMS: number,
    target: string, // TODO: emum
    unit: GlucoseUnit,
  }>,
  markers?: Array<CGMReportMarkerPoint>,
  showTimeAxis?: boolean,
  showValueAxis?: boolean,
  width: number,
  height?: number,
  timesToHighlightMS?: Array<number>,
  xTickFrequencyHours?: number,
  xTickFirstOffsetHours?: number,
  xDomainMS?: [number, number],
  maxXTicks?: number,
  xTickMinSpacingHours?: number,
  agpSummary?: boolean,
  showTargetRange?: boolean,
}) => {
  const { width, xTickFrequencyHours, maxXTicks } = {
    ...props,
    width: props.width ?? 240,
    xTickFrequencyHours: props.xTickFrequencyHours ?? 2,
    maxXTicks: props.maxXTicks ?? 6,
  };

  const height = props.height ?? 110;
  const bottomPadding = 30;
  const topPadding = 5;
  const leftPadding = 0;
  const { flags } = useCurrentPatientData();
  const glucoseUnit = flags?.patient_glucose_units_mmol_l === false ? 'mg/dL' : 'mmol/L';
  const glucoseRangeQuery = useGlucoseRanges();
  const glucoseRangesToUse = glucoseRangeQuery.data;

  const data = React.useMemo(() => {
    if (!props.xDomainMS) {
      return props.data;
    }
    const [xMinMS, xMaxMS] = props.xDomainMS;
    return props.data.filter(d => d.xTimestampMS >= xMinMS && d.xTimestampMS <= xMaxMS)
      .map(d => ({
        ...d,
        value: formatGlucoseValue(glucoseUnit, d.value).value,
        p5: d.p5 && formatGlucoseValue(glucoseUnit, d.p5).value,
        p25: d.p25 && formatGlucoseValue(glucoseUnit, d.p25).value,
        p75: d.p75 && formatGlucoseValue(glucoseUnit, d.p75).value,
        p95: d.p95 && formatGlucoseValue(glucoseUnit, d.p95).value,
      }));
  }, [props.data, props.xDomainMS, glucoseUnit]);

  const dataYDomain = React.useMemo(() => [
    d3Array.min(data, d => d.p5 || d.p25 || d.value) as number,
    d3Array.max(data, d => d.p95 || d.p75 || d.value) as number,
  ], [data]);

  const dataXDomain = React.useMemo(() => (
    props.xDomainMS ?? d3Array.extent(data, d => d.xTimestampMS) as [number, number]
  ), [data, props.xDomainMS]);

  const yDomain = React.useMemo(() => {
    const formattedGlucoseChartLB = formatGlucoseValue(glucoseUnit, glucoseChartLowerBound).value;
    const formattedGlucoseChartUB = formatGlucoseValue(glucoseUnit, glucoseChartUpperBound).value;

    const roundToClosestEven = (value: number) => {
      return Math.ceil(value / 2) * 2;
    };
    return [
      dataYDomain[0] && Math.min(formattedGlucoseChartLB, roundToClosestEven(dataYDomain[0])),
      dataYDomain[0] && Math.max(formattedGlucoseChartUB, roundToClosestEven(dataYDomain[1])),
    ].filter(v => !isNaN(v)) as [number, number];
  }, [dataYDomain, glucoseUnit]);

  const [xMinMS, xMaxMS] = dataXDomain;

  const xTickValues = React.useMemo(() => {
    const firstTickOffset = (props.xTickFirstOffsetHours || 0) * 60 * 60 * 1000;
    let res = _.range(
      roundTimestampToMinute(xMinMS + firstTickOffset, 60),
      xMaxMS,
      1000 * 60 * 60 * xTickFrequencyHours,
    )
      .filter(x => x >= xMinMS && x <= (xMaxMS - 1000 * 60 * 15));
    if (res.length > maxXTicks) {
      res = res.filter((_, i) => i % 2 === 0);
    }

    const firstTimeToHighlight = props.timesToHighlightMS?.[0];
    if (firstTimeToHighlight) {
      const xTickNearestToMealTime = res.reduce((acc, x) => {
        const delta = Math.abs(x - firstTimeToHighlight);
        const isCloser = delta < Math.abs(acc - firstTimeToHighlight);
        if (isCloser && delta < 1000 * 60 * 45) {
          return x;
        }
        return acc;
      }, Infinity);
      res = res.map(x => {
        if (x === xTickNearestToMealTime) {
          return firstTimeToHighlight;
        }
        return x;
      });
    }

    if (props.xTickMinSpacingHours) {
      const getDelta = (a: number, b?: number) => Math.abs(a - b ?? Infinity);
      res = res.filter((x, i) => {
        if (x == firstTimeToHighlight) {
          return true;
        }

        const prev = res[i - 1];
        if (prev == firstTimeToHighlight) {
          const delta = getDelta(x, prev);
          if (delta < 1000 * 60 * 60 * props.xTickMinSpacingHours) {
            return false;
          }
        }

        const next = res[i + 1];
        const delta = getDelta(x, next);
        if (delta < 1000 * 60 * 60 * props.xTickMinSpacingHours) {
          return false;
        }
        return true;
      });
    }
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xMinMS, xMaxMS, props.timesToHighlightMS, xTickFrequencyHours]);

  const markersWithYValues: MarkerWithYValue[] = React.useMemo(() => {
    if (!props.data || !props.markers) {
      return [];
    }
    const res = props.markers
      .map(marker => {
        const cgmDataPoint = props.data
          .find(d => Math.abs(d.xTimestampMS - +marker.xTimestampMS) / 1000 < 5 * 60);
        return {
          ...marker,
          value: formatGlucoseValue(glucoseUnit, cgmDataPoint?.value).value || 8,
        };
      })
      .filter(m => !isNaN(m.value) && m.value !== null);
    return fixMarkerOverlaps(res, yDomain, props.showTimeAxis);
  }, [props.markers, props.data, yDomain, props.showTimeAxis, glucoseUnit]);

  const hiddenAxisStyle = {
    axis: { stroke: 'transparent' },
    ticks: { stroke: 'transparent' },
    grid: { stroke: 'rgba(0,0,0,0.2)' },
    tickLabels: { fill: 'transparent' },
  };
  return (
    <RxVictoryChart
      domain={{
        y: yDomain,
        x: dataXDomain,
      }}
      domainPadding={{ y: [0, 0], x: [0, 0] }}
      height={height}
      width={width}
      padding={{
        top: topPadding,
        bottom: bottomPadding,
        left: props.agpSummary ? 18 : 0,
        right: props.showValueAxis ? 25 : 0,
      }}
      // containerComponent={<VictoryContainer responsive={p.responsive} />}
    >
      {!!data.length && <RangeClipPaths glucoseUnits={glucoseUnit} glucoseRanges={glucoseRangesToUse} />}

      <VictoryAxis
        fixLabelOverlap
        tickFormat={x => (
          x != roundTimestampToMinute(x, 60)
            ? DateTime.fromSeconds(x / 1000).toFormat('h:mma')
            : DateTime.fromSeconds(x / 1000).toFormat('ha')
        )}
        tickValues={xTickValues}
        offsetY={35}
        style={!props.showTimeAxis ? hiddenAxisStyle : {
          axis: { stroke: 'transparent' },
          ticks: {
            stroke: 'rgba(0,0,0,0.2)',
            size: 5,
          },
          tickLabels: {
            fontSize: props.agpSummary ? 4 : 8,
            textAnchor: 'middle',
            fill: 'rgba(0,0,0,0.6)',
            padding: 5,
          },
          axisLabel: {
            fontSize: 4,
            padding: 22,
          },
        }}
      />

      <VictoryAxis
        dependentAxis
        orientation={props.agpSummary ? 'left' : 'right'}
        tickCount={10}
        fixLabelOverlap
        label={props.agpSummary
          ? t('Average Glucose ({{ unit }})', { unit: glucoseUnit, 'interpolation': { 'escapeValue': false } })
          : ''}
        style={!props.showValueAxis ? hiddenAxisStyle : {
          axis: { stroke: 'transparent' },
          grid: { stroke: 'rgba(0,0,0,0.2)' },
          tickLabels: {
            fontSize: props.agpSummary ? 4 : 8,
            textAnchor: 'middle',
            fill: 'rgba(0,0,0,0.6)',
            padding: 5,
          },
          axisLabel: {
            fontSize: 4,
            padding: 14,
          },
        }}
      />
      {!!data.length && props.showTargetRange && (
        <VictoryArea
          style={{
            data: {
              fill: 'rgba(219, 235, 214, 0.6)',
              // stroke: 'rgba(110, 181, 34, 1)',
            },
          }}
          data={[
            {
              x: dataXDomain[0],
              y0: getGlucoseChartTargetRange(glucoseRangesToUse).lower,
              y: getGlucoseChartTargetRange(glucoseRangesToUse).upper,
            },
            {
              x: dataXDomain[1],
              y0: getGlucoseChartTargetRange(glucoseRangesToUse).lower,
              y: getGlucoseChartTargetRange(glucoseRangesToUse).upper,
            },
          ]}
        />
      )}
      {!!data.length && Object.entries(glucoseRangesToUse).map(([targetName, target]) => (
        <VictoryGroup key={targetName}>
          {data[0]?.p5 && data[0]?.p95 && (
            <VictoryArea
              data={data}
              x="xTimestampMS"
              y="p95"
              y0="p5"
              style={{
                data: {
                  fill: target.color,
                  fillOpacity: 0.2,
                  stroke: 'none',
                },
              }}
              groupComponent={<g clipPath={`url(#glucose-target-${targetName}-clip-${yDomain[0]}-${yDomain[1]})`} />}
            />
          )}

          {data[0]?.p25 && data[0]?.p75 && (
            <VictoryArea
              data={data}
              x="xTimestampMS"
              y="p75"
              y0="p25"
              style={{
                data: {
                  fill: target.color,
                  fillOpacity: 0.4,
                  stroke: 'none',
                },
              }}
              groupComponent={<g clipPath={`url(#glucose-target-${targetName}-clip-${yDomain[0]}-${yDomain[1]})`} />}
            />
          )}

          <VictoryLine
            data={data}
            x="xTimestampMS"
            y="value"
            interpolation="natural"
            style={{
              data: {
                stroke: target.color,
                strokeWidth: 2,
              },
            }}
            groupComponent={<g clipPath={`url(#glucose-target-${targetName}-clip-${yDomain[0]}-${yDomain[1]})`} />}
          />
        </VictoryGroup>
      ))}

      {!!props.markers && (
        <VictoryScatter
          dataComponent={<PointMarker />}
          style={{ data: { fill: '#c43a31' } }}
          size={6}
          data={markersWithYValues}
          x="xTimestampMS"
          y="value"
        />
      )}
    </RxVictoryChart>
  );
};
