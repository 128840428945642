import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { DateTime, Duration } from 'luxon';
import { useMemo } from 'react';
import { cgmApi } from '../../api';
import {
  GlucoseTimeInRange,
  PatientCGMDataRefetchResult,
  PatientCgmReportDataResponse,
  PatientCgmWholeSummaryResponseGlucosePeriodStatsInner,
  PatientGlucoseDataResponse,
} from '../../api/generated';
import { config } from '../../config';
import { useCurrentPatientData, useStore } from '../../context';
import {
  AppDataStoragePlatform,
  AppDataStorageScope,
  useAppDataStorageQuery,
} from '../../services/PatientAppDataService';
import { formatDateTime } from '../../utils/formatDate';
import {
  formatGlucoseValue,
  getDefaultGlucoseTargets,
  GlucoseTargetName,
  GlucoseTargetsType,
  GlucoseUnit,
} from './cgmUtils';

const mockData: PatientGlucoseDataResponse[] = require('./data/mockCgmData.json');
// const mockDataAGP: PatientCgmReportDataResponse = require('../../reports/sample-data/DO_NOT_COMMIT-1849.json');
const mockDataAGP: PatientCgmReportDataResponse = null;

export const determineTarget = (value: number, glucoseRanges: GlucoseTargetsType): GlucoseTargetName => {
  return Object.keys(glucoseRanges).find(targetName => {
    const target = glucoseRanges[targetName];
    return (value >= target.mmMolLower && value <= target.mmMolUpper);
  }) as GlucoseTargetName;
};

export interface GlucoseDataPoint {
  value: number;
  xTimestampMS: number;
  target: GlucoseTargetName;
  unit: GlucoseUnit;
  p5?: number;
  p25?: number;
  p75?: number;
  p95?: number;
  count?: number;
}

type GlucoseRangeUpperThresholds = {
  [key in GlucoseTargetName]: number;
};

const THRESHOLD_ORDER = ['lowDanger', 'low', 'target', 'high', 'highDanger'] as (keyof GlucoseTargetsType)[];

const getGlucosUpperThresholdsFromGlucoseTargets = (glucoseTargets: Partial<GlucoseTargetsType>) => {
  return Object.fromEntries(
    Object.entries(glucoseTargets)
      .map(([key, value]) => [key, value.mmMolUpper])
      .filter(([_, value]) => value !== undefined),
  ) as GlucoseRangeUpperThresholds;
};

export const refetchCgmData = (patientId: number) => {
  const res = cgmApi.appApiCgmApiPostCgmDataRefetch({
    patient_id: patientId,
  });
  return res
    .then(res => {
      console.log('CGMDataService: refetch result:', res.data);
      return {
        needsReauth: false,
        refetchResult: res.data || [],
        hasCgmConnection: res.data.length > 0,
      };
    })
    .catch(err => {
      if (!(err instanceof AxiosError)) {
        throw err;
      }
      if (err.response?.data?.error != 'dexcom_need_reauth') {
        throw err;
      }
      return {
        needsReauth: true,
        refetchResult: [] as PatientCGMDataRefetchResult[],
        hasCgmConnection: false,
      };
    });
};

export const useCgmRefetch = () => {
  const { patient } = useStore();
  const { flags } = useCurrentPatientData();
  const enabled = !!(patient?.patient_id && (flags?.patient_show_dexcom_oauth || config.IS_LOCAL || config.IS_DEV));

  const query = useQuery(['cgm-refresh', patient.patient_id], async () => {
    return refetchCgmData(patient.patient_id);
  }, {
    cacheTime: 1000 * 60 * 60 * 15,
    refetchInterval: 1000 * 60 * 15,
    enabled,
    refetchOnMount: false,
  });

  return {
    query,
    enabled,
    needsReauth: !!query.data?.needsReauth,
    refetchResult: query.data?.refetchResult || [],
    hasCgmConnection: query.data?.hasCgmConnection || false,
  };
};

export const useGlucoseRanges = () => {
  const { patient } = useStore();
  const { flags } = useCurrentPatientData();
  const enabled = !!(patient?.patient_id && (flags?.patient_show_dexcom_oauth || config.IS_LOCAL || config.IS_DEV));
  const glucoseUnits = (flags?.patient_glucose_units_mmol_l ? 'mmol/L' : 'mg/dL') ?? 'mmol/L';

  const glucoseRangeQuery = useAppDataStorageQuery({
    platform: AppDataStoragePlatform.general,
    scope: AppDataStorageScope.cgm,
    name: 'glucose-ranges-mmol-upper',
    default: null,
    optimisticLoad: true,
    enabled,
  });

  const getUpperThreshold = (range: keyof GlucoseTargetsType) => {
    return (
      glucoseRangeQuery.data?.app?.[range]
        ?? glucoseRangeQuery.data?.api?.[range]
        ?? glucoseRangeQuery.data?.default?.[range]?.mmMolUpper
        ?? 0 // this should never happen, but prevent exceptions
    );
  };

  const data = useMemo(() => {
    return THRESHOLD_ORDER.reduce((result, range, index) => {
      const defaults = getDefaultGlucoseTargets(glucoseUnits)?.[range];
      const mmMolLower = index ? getUpperThreshold(THRESHOLD_ORDER[index - 1]) : 0;
      const mmMolUpper = getUpperThreshold(range);
      result[range] = {
        ...defaults,
        mmMolLower: mmMolLower || defaults?.mmMolLower,
        mmMolUpper: mmMolUpper || defaults?.mmMolUpper,
        valueLower: _.round(
          formatGlucoseValue(glucoseUnits, mmMolLower, 'mmol/L').value,
          glucoseUnits === 'mmol/L' ? 1 : 0,
        ) || defaults?.valueLower,
        valueUpper: _.round(
          formatGlucoseValue(glucoseUnits, mmMolUpper, 'mmol/L').value,
          glucoseUnits === 'mmol/L' ? 1 : 0,
        ) || defaults?.valueUpper,
        valueUnit: glucoseUnits,
      };
      console.log('result:', result);
      return result;
    }, {} as GlucoseTargetsType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [glucoseRangeQuery.data, glucoseUnits]);

  return {
    data,
    isSuccess: glucoseRangeQuery.isSuccess,
    isLoading: glucoseRangeQuery.isLoading,
    isError: glucoseRangeQuery.isError,
    updateGlucoseRanges: (ranges: Partial<GlucoseTargetsType>) => {
      glucoseRangeQuery.set((prevData) => {
        return {
          ...prevData,
          app: _.merge({}, prevData?.app, getGlucosUpperThresholdsFromGlucoseTargets(ranges)),
        };
      });
    },
    resetGlucoseRanges: () => {
      glucoseRangeQuery.set((prevData) => {
        return {
          ...prevData,
          app: undefined,
        };
      });
    },
  };
};

export const useProcessedCgmData = (glucoseData: PatientGlucoseDataResponse[]): GlucoseDataPoint[] => {
  const glucoseRangeQuery = useGlucoseRanges();

  return useMemo(() => {
    // console.log('useProcessedCgmData: glucoseData:', glucoseData);
    return glucoseData.map(g => ({
      value: g.value,
      xTimestampMS: DateTime.fromISO(g.timestamp).toMillis(),
      target: determineTarget(g.value, glucoseRangeQuery.data),
      unit: g.unit as GlucoseUnit,
    })).filter(x => x.value > 0);
  }, [glucoseData, glucoseRangeQuery.data]);
};

export const useCgmData = (opts: {
  patientId: string | number,
  timeSinceLocal: Date | null,
  timeUntilLocal: Date | null,
}) => {
  const { patientId, timeSinceLocal, timeUntilLocal } = opts;
  const { flags } = useCurrentPatientData();
  const enabled = !!(flags?.patient_show_dexcom_oauth || config.IS_LOCAL || config.IS_DEV);
  // eslint-disable-next-line i18next/no-literal-string
  const queryKey = ['cgm-data', opts.patientId, enabled, timeSinceLocal?.toISOString(), timeUntilLocal?.toISOString()];
  const query = useQuery(queryKey, async () => {
    // console.log('In useCgmData query:', queryKey);

    if (!timeSinceLocal || !timeUntilLocal) {
      return [];
    }

    if (!enabled) {
      return [];
    }

    if (config.IS_LOCAL || config.IS_DEV) {
      const res: PatientGlucoseDataResponse[] = [];
      for (let idx = 0;; idx += 1) {
        const curTimestamp = timeSinceLocal.getTime() + (idx * 1000 * 60 * 5);
        if (curTimestamp > timeUntilLocal.getTime()) {
          break;
        }
        const mockValue = mockData[idx % mockData.length];
        res.push({
          timestamp: new Date(curTimestamp).toISOString(),
          unit: mockValue.unit,
          value: mockValue.value,
        });
      }
      return res;
    }

    const res = await cgmApi.appApiCgmV2ApiV2GetCgmData({
      patient_id: Number(patientId),
      time_since_local: formatDateTime(timeSinceLocal),
      time_until_local: formatDateTime(timeUntilLocal),
    });
    return res.data.glucose || [];
  });

  const glucoseData: PatientGlucoseDataResponse[] = query.data || [];

  return {
    enabled,
    query,
    timeSinceLocal,
    timeUntilLocal,
    glucoseData,
  };
};

export const useProcessedAgpData = (
  glucosePeriodStats: PatientCgmWholeSummaryResponseGlucosePeriodStatsInner[],
  dateSinceLocal: string,
): GlucoseDataPoint[] => {
  const glucoseRangeQuery = useGlucoseRanges();
  return useMemo(() => {
    return glucosePeriodStats.map(g => ({
      value: g.avg,
      count: g.count,
      xTimestampMS: new Date(dateSinceLocal + 'T' + g.time_of_day).getTime(),
      target: determineTarget(g.avg, glucoseRangeQuery.data),
      unit: g.unit as GlucoseUnit,
      p5: g.p5,
      p25: g.p25,
      p75: g.p75,
      p95: g.p95,
    })).filter(x => x.value > 0);
  }, [glucosePeriodStats, dateSinceLocal, glucoseRangeQuery.data]);
};

export const useAgpData = (opts: {
  patientId: string | number,
  dateSinceLocal: string,
  dateUntilLocal: string,
}) => {
  const { patientId, dateSinceLocal, dateUntilLocal } = opts;
  const { flags } = useCurrentPatientData();
  const enabled = !!(flags?.patient_show_dexcom_oauth || config.IS_LOCAL || config.IS_DEV);
  // eslint-disable-next-line i18next/no-literal-string
  const queryKey = ['agp-data', opts.patientId, enabled, dateSinceLocal, dateUntilLocal];
  const query = useQuery(queryKey, async () => {
    console.log('CGMDataService: fetching AGP data for time range:', queryKey);

    if (!dateSinceLocal || !dateUntilLocal) {
      return null;
    }
    if (!enabled) {
      return null;
    }
    // if (config.IS_LOCAL || config.IS_DEV) {
    //   if (!mockDataAGP) {
    //     console.log('no AGP mock data');
    //     return null;
    //   }
    //   return mockDataAGP;
    // }
    const res = await cgmApi.appApiCgmV2ApiV2GetCgmWholeSummary({
      patient_id: Number(patientId),
      date_since: dateSinceLocal,
      date_until: dateUntilLocal,
    });
    return res.data || null;
  });
  const queryData: any = query.data;
  const glucosePeriodStats: PatientCgmWholeSummaryResponseGlucosePeriodStatsInner[] =
    queryData?.whole_summary?.glucose_period_stats
    || queryData?.glucose_period_stats
    || [];

  const timeInRange: GlucoseTimeInRange = queryData?.glucose_time_in_range;
  const avgGlucose: number = queryData?.glucose_average;
  const stdDevGlucose: number = queryData?.glucose_std_dev;
  const unit: GlucoseUnit = queryData?.whole_summary?.glucose_period_stats?.[0]?.unit; // undefined

  return {
    enabled,
    query,
    dateSinceLocal,
    dateUntilLocal,
    glucosePeriodStats,
    timeInRange,
    avgGlucose,
    stdDevGlucose,
    unit,
  };
};

const getNumDays = (dateSinceLocal: string, dateUntilLocal: string) => {
  return DateTime.fromISO(dateUntilLocal).diff(DateTime.fromISO(dateSinceLocal), 'days').days + 1;
};

export const useTimeInRangeData = (props: {
  dateSinceLocal: string,
  dateUntilLocal: string,
}) => {
  const { patient } = useStore();
  const { query, timeInRange, glucosePeriodStats, unit } = useAgpData({
    patientId: patient.patient_id,
    dateSinceLocal: props.dateSinceLocal,
    dateUntilLocal: props.dateUntilLocal,
  });
  const isGlucosePeriodStats = !!glucosePeriodStats?.length;
  const totalMinutesActive = timeInRange?.minutes_total ?? 0;

  const chartData = {
    glucoseRangesUpper: timeInRange && Object.fromEntries(timeInRange.values?.map(v => [v.target_name, v.mmol_upper])),
  };

  const getHrMinsPerDayFromMinutes = (minutes: number) => {
    if (!minutes) {
      return '0min';
    }
    const minutesPerDay = Math.round(minutes / totalMinutesActive * 1440);
    const timeStr = Duration.fromObject({ minute: minutesPerDay }).toFormat('h:mm')?.split(':');

    if (timeStr[0] === '0') {
      return timeStr[1] + 'min';
    }
    if (timeStr[1] === '00') {
      return timeStr[0] + 'h';
    }
    return timeStr[0] + 'h ' + timeStr[1] + 'min';
  };
  const getPercentFromMinutes = (minutes: number) => {
    return Math.round((minutes / totalMinutesActive) * 100) + '%';
  };

  const minuteData = timeInRange
    && Object.fromEntries(timeInRange.values?.map(v => [v.target_name, v.minutes_in_range]));
  const hrMinData = timeInRange && Object.fromEntries(
    Object.entries(minuteData).map(([key, value]) => [key, getHrMinsPerDayFromMinutes(value)]),
  );
  const percentData = timeInRange && Object.fromEntries(
    Object.entries(minuteData).map(([key, value]) => [key, getPercentFromMinutes(value)]),
  );

  return { isGlucosePeriodStats, chartData, minuteData, totalMinutesActive, hrMinData, percentData, unit };
};

export const useGlucoseStats = (props: {
  dateSinceLocal: string,
  dateUntilLocal: string,
}) => {
  const { patient } = useStore();
  // const { flags } = useCurrentPatientFlags();
  const { query, avgGlucose, stdDevGlucose, glucosePeriodStats, timeInRange, unit } = useAgpData({
    patientId: patient.patient_id,
    dateSinceLocal: props.dateSinceLocal,
    dateUntilLocal: props.dateUntilLocal,
  });
  const isGlucosePeriodStats = !!glucosePeriodStats?.length;
  const numDays = getNumDays(props.dateSinceLocal, props.dateUntilLocal);
  const totalMinutesActive = timeInRange?.minutes_total ?? 0;
  const percentTimeActive = (totalMinutesActive / (numDays * 24 * 60)) * 100;

  // const glucoseUnits = (flags?.patient_glucose_units_mmol_l ? 'mmol/L' : 'mg/dL') ?? 'mmol/L';
  const gmiMgDl = 3.31 + 0.02392 * formatGlucoseValue(unit, avgGlucose, 'mg/dL').value;
  const cv = stdDevGlucose / avgGlucose * 100;

  return { isGlucosePeriodStats, numDays, percentTimeActive, avgGlucose, gmiMgDl, cv, unit };
};
